<template>
    <!-- OA审批 状态 -->
    <div class="status-approvals">
        <status-list :list="list">
        </status-list>
    </div>
</template>
<script>
    import StatusList from './components/Status/index.vue';
    export default {
        name: "StatusApprovals",
        components: {
            StatusList
        },
        props: {
            list: {
                type: Array
            }
        }
    }
</script>
<style lang="scss" scoped>
    .status-approvals {
        margin-top: 10px;
    }
</style>