// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/coworkingOAApproval/bj.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/images/coworkingOAApproval/create.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/images/coworkingOAApproval/create-hover.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".common-approvals[data-v-321536bf]{width:100%;height:132px;background:#fff;border-radius:0 0 4px 4px;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;padding:15px 18px 21px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:100% 100%}.common-approvals .group-title[data-v-321536bf]{display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-moz-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.common-approvals .group-title[data-v-321536bf] .el-button.btn-create span{font-family:Microsoft YaHei;font-weight:400;font-size:14px;color:#666}.common-approvals .group-title .btn-create[data-v-321536bf]{display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-webkit-align-items:center;-moz-box-align:center;-ms-flex-align:center;align-items:center;background:hsla(0,0%,100%,.2);border-radius:6px;border:1px solid #d9d9d9}.common-approvals .group-title[data-v-321536bf] .create-icon{display:block;width:12px;height:12px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-repeat:no-repeat;background-size:100%;margin-right:4px}.common-approvals .group-title[data-v-321536bf] .el-button.btn-create:hover{border-color:#3c7fff!important}.common-approvals .group-title[data-v-321536bf] .el-button.btn-create:hover i{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")!important}.common-approvals .group-title[data-v-321536bf] .el-button.btn-create:hover span{color:#3c7fff}.common-approvals .add-approvals[data-v-321536bf]{width:54px;height:54px;text-align:center;line-height:54px;border:1px dashed #d9d9d9;border-radius:4px;cursor:pointer}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
