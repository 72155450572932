<template>
    <div class="all-approvals">
        <common-title>
            <div class="seacrh-title">
                <div class="title">全部审批单</div>
                <div class="search-group">
                    <el-input
                        class="search-int"
                        v-model="listQuery.formName"
                        placeholder="搜索"
                        prefix-icon="el-icon-search"
                        clearable
                        @keyup.enter.native="handleQuery"
                    >
                    </el-input>
                    <el-button 
                        class="btn-create" 
                        icon="create-icon"
                        @click="goToDeatil"
                        v-has-permi="['oaForm:add']"
                    >
                        创建
                    </el-button>
                    <el-button 
                        class="btn-custom" 
                        icon="custom-icon"
                        @click="goTolist"
                        v-has-permi="['oaForm:all']"
                    >
                        管理后台
                    </el-button>
                </div>
            </div>
        </common-title>
        <tab-approvals
            :tabActiveName="tabActiveName"
            :scrollList="scrollList"
        >
        </tab-approvals>
    </div>
</template>
<script>
    import CommonTitle from './components/Title.vue';
    import TabApprovals from './components/TabApprovals/index.vue';
    export default {
        name: "AllApprovals",
        components: {
            CommonTitle,
            TabApprovals
        },
        props: {
            listQuery: {
                type: Object
            },
            tabActiveName: {
                type: String,
                default: ''
            },
            scrollList: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        methods: {
            handleQuery() {
                this.$emit('handleQuery')
            },
            goToDeatil() {
                sessionStorage.setItem('GO_OA_MANAGE_DETAIL', 'go');
                this.$router.push({name: 'CoworkingCampusOAApprovalManage'})
            },
            goTolist() {
                this.$router.push({name: "CoworkingCampusOAApprovalManage"})
            }
        }
    }
</script>
<style lang="scss" scoped>
    .all-approvals {
        margin-top: 11px;
        width: 100%;
        height: 352px;
        background: #FFFFFF;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 13px 20px;
        .seacrh-title {
            display: flex;
            justify-content: space-between;
            .title {
                line-height: 32px;
            }
            .search-group {
                display: flex;
                .search-int {
                    width: 180px;
                    height: 32px;
                    margin-right: 10px;
                    /deep/ .el-input__inner {
                        border: 1px solid #D9D9D9;
                        border-radius: 6px;
                    }
                }
                .btn-create {
                    display: flex;
                    align-items: center;
                    background: rgba(255,255,255,0.2);
                    border-radius: 6px;
                    border: 1px solid #D9D9D9;
                }
                .btn-custom {
                    display: flex;
                    align-items: center;
                    background: rgba(255,255,255,0.2);
                    border-radius: 6px;
                    border: 1px solid #D9D9D9;
                    padding: 10px 19px !important;
                }
                /deep/ .el-button.btn-create span {
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #666666;
                }
                /deep/ .el-button.btn-custom span {
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #666666;
                }
                /deep/ .el-button.btn-create:hover {
                    border-color: #3C7FFF !important;
                    i {
                        background-image: url('@/assets/images/coworkingOAApproval/create-hover.png') !important;
                    }
                    span {
                        color: #3C7FFF;
                    }
                }
                /deep/ .el-button.btn-custom:hover {
                    border-color: #3C7FFF !important;
                    i {
                        background-image: url('@/assets/images/coworkingOAApproval/manage-hover.png') !important;
                    }
                    span {
                        color: #3C7FFF;
                    }
                }
                /deep/ .create-icon {
                    display: block;
                    width: 12px;
                    height: 12px;
                    background-image: url('@/assets/images/coworkingOAApproval/create.png');
                    background-repeat: no-repeat;
                    background-size: 100%;
                    margin-right: 4px;
                }
                /deep/ .custom-icon {
                    display: block;
                    width: 13px;
                    height: 13px;
                    background-image: url('@/assets/images/coworkingOAApproval/manage.png');
                    background-repeat: no-repeat;
                    background-size: 100%;
                    margin-right: 4px;
                }
            }
        }
    }
</style>