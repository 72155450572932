<template>
    <!-- 校园OA/OA审批/常用审批单 -->
    <div class="common-approvals">
        <common-title>
            <div class="group-title">
                <div class="title">常用审批单</div>
                <el-button 
                    class="btn-create" 
                    icon="create-icon"
                    @click="changeStatus" 
                    :loading="loadingBtn"
                >
                    {{ btnName }}
                </el-button>
            </div>
        </common-title>
        <approvals-list
            v-if="list.length > 0"
            :commonStatus="commonStatus"
            :list="list"
        >
        </approvals-list>
        <div 
            v-else
            class="add-approvals"
            @click="addStatus"
        >
            <i class="el-icon-plus"></i>
        </div>
    </div>
</template>
<script>
    import CommonTitle from './components/Title.vue';
    import ApprovalsList from './components/Approvals/List.vue';
    export default {
        name: "CommonApprovals",
        components: {
            CommonTitle,
            ApprovalsList
        },
        props: {
            list: Array,
            commonStatus: String,
            loadingBtn: Boolean
        },
        data() {
            return {
                status: 'edit'
            }
        },
        computed: {
            btnName() {
                switch (this.commonStatus) {
                    case 'edit':
                        return '编辑';
                    case 'add':
                        return '保存';
                    default:
                        break;
                }
            }
        },
        methods: {
            addStatus() {
                this.$emit('changeCommonStatus', 'edit')
            },
            /**
             * @Description: 切换按钮状态
             * @Author: 武东超
             * @Date: 2024-09-04 11:25:42
             */            
            changeStatus() {
                this.$emit('changeCommonStatus', this.commonStatus)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .common-approvals {
        width: 100%;
        height: 132px;
        background: #FFFFFF;
        border-radius: 0 0 4px 4px;
        box-sizing: border-box;
        padding: 15px 18px 21px;
        background-image: url('@/assets/images/coworkingOAApproval/bj.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .group-title {
            display: flex;
            justify-content: space-between;
            /deep/ .el-button.btn-create span {
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #666666;
            }
            .btn-create {
                display: flex;
                align-items: center;
                background: rgba(255,255,255,0.2);
                border-radius: 6px;
                border: 1px solid #D9D9D9;
            }
            /deep/ .create-icon {
                display: block;
                width: 12px;
                height: 12px;
                background-image: url('@/assets/images/coworkingOAApproval/create.png');
                background-repeat: no-repeat;
                background-size: 100%;
                margin-right: 4px;
            }
            /deep/ .el-button.btn-create:hover {
                border-color: #3C7FFF !important;
                i {
                    background-image: url('@/assets/images/coworkingOAApproval/create-hover.png') !important;
                }
                span {
                    color: #3C7FFF;
                }
            }
        }
        .add-approvals {
            width: 54px;
            height: 54px;
            text-align: center;
            line-height: 54px;
            border: 1px dashed #D9D9D9;
            border-radius: 4px;
            cursor: pointer;
        }
    }
</style>